html {
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

body {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Roboto';
}

img {
  max-width: 100%;
}
